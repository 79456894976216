<template>
	<div>
		<GHheader></GHheader>
		<router-view :key="key"></router-view>
		<g-hfoter></g-hfoter>
	</div>
</template>

<script>
	import GHheader from "@/components/GHheader.vue"
	import GHfoter from "@/components/GHfoter.vue"
	export default{
		components:{
			GHfoter,
			GHheader
		},
		computed:{
			key(){ 
				return this.$route.fullPath
			}
		},
	}
</script>

<style scoped> 
	.index ~ .bounds >>> .foter{
		border-top: none;
		padding-top: 0;
	}
	 
</style>