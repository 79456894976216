<template>

	<header class="header" :class="{'pageindex':isindex}">
			<div class="bounds flex-between flex-vcenter pcheader">
				<a target="_blank" href="/" class="logo">
					<img v-if="isindex" class="logo" :src="config.realLogo" />
					<img v-if="isindex" class="logog" :src="config.realFootLogo" />
					<img v-else class="logo" :src="config.realFootLogo" />
				</a>
				<div class="menu">
					<ul class="flex-start">
						<li><a href="/">首页</a></li>
						<li v-for="item,index in menulist"
							:class="{'current':item.menuId==current.menuId,'toright':index>=menulist.length/2}">
							<span v-if="item.menuType==1">{{item.menuName}}</span>
							<a target="_blank" v-else :href="itemUrl(item)">{{item.menuName}}</a>
							<ul v-if="item.children.length">
								<li v-for="sitem in item.children">
									<a target="_blank" :href="itemUrl(sitem)">{{sitem.menuName}}</a>
								</li>
							</ul>
						</li>
					</ul>
					<div class="office">
						<a target="_blank" href="http://oa.lyguohongtouzi.com/login.jsp">
							<img v-if="isindex" class="bgg" src="@/assets/oag.png" />
							<img v-if="isindex" class="bgw" src="@/assets/oaw.png" />
							<img v-else src="@/assets/oaw.png" />
						</a> 
					</div>
					<div class="search">
						<a target="_blank" href="/search"><i class="el-icon-search"></i></a>
					</div>
				</div>
			</div>
			<div class="mobelHeader">
				<div class="title">{{current.title?current.title:''}}</div>
				<div class="rightBtn" @click="show=!show" :class="{'active':show}"><span></span></div>
				<div class="shadow" v-if="show" @click="show=false">
				</div>
				<ul v-if="show" class="menus">
					<li>
						<a target="_blank" href="/">首页</a>
					</li>
					<li v-for="item,i in menulist">
						<a target="_blank" :href="menuUrl(item)">{{item.menuName}}</a>
					</li>
				</ul>
			</div>
		
	</header>
</template>

<script>
	export default {
		computed: {
			config() {
				return this.$store.getters.config
			},
			menulist() {
				return this.$store.getters.menus
			},
			current() {
				var menu = {
					title: '首页'
				};
				if (!this.isindex && this.menulist) {
					this.menulist.forEach((item, i) => {
						if (item.menuId == this.urlid) {
							menu = {
								title: item.menuName,
								menuId: item.menuId
							}
						}
						item.children.forEach(ite => {
							if (ite.menuId == this.urlid) {
								menu = {
									title: ite.menuName,
									menuId: item.menuId
								}
							}
						})
					})
				}
				return menu
			},
			isindex() {
				return this.$route.path == '/'
			},
			urlid() {
				return this.$route.query.mid ? this.$route.query.mid : this.$route.query.id
			}
		},
		data() {
			return {
				show: false
			}
		},
		methods: {
			itemUrl(item) {
				return this.$pageUrl(item)
			},
			menuUrl(item) {
				let sitem = item.children && item.children.length > 0 ? item.children[0] : item
				return this.$pageUrl(sitem)
			}
		}
	}
</script>

<style scoped>
	.pageindex {
		background-color: rgba(255, 255, 255, 0);
		transition-duration: .6s;
	}

	.header {
		position: relative;
		height: 6.275rem;
	}

	/* header .bounds:hover::after{
		content: '';
		background-color: #eee;
		position: absolute;
		width: 100%;
		height: 1px;
		z-index: 999;
		bottom: 0;
		left: 0;
	} */

	.pageindex .flex-start li li a {
		color: #333;
	}


	.pageindex .logog {
		opacity: 0;
		transition-duration: .6s;
	}

	a.logo {
		display: block;
		width: 22.75rem;
		height: 4.0625rem;
		position: relative;
	}

	a.logo img {
		position: absolute;
	}

	.header ul.flex-start {
		height: 5.75rem;
		padding-right: 12rem;
	}
	.header.pageindex ul.flex-start { 
		padding-right: 16rem;
	}

	.header .menu {
		position: relative; 
	}

	.header .search {
		display: none;
	}

	header.pageindex .search {
		position: absolute; 
		display: block;
		right: 0;
		top: 1.6rem;
	}
	
	
	.pageindex .el-icon-search{
		color: #fff;
		font-size: 2rem;
	}

	.header .flex-start li {
		position: relative;
	}

	.header .flex-start li:hover {
		border-bottom: #986F5A solid 0.25rem;
	}
 

	.header .flex-start li:hover span,
	.header .flex-start a:hover,
	.header .flex-start li li:hover a {
		color: #986F5A;
	}

	.header .flex-start li ul {
		position: absolute; 
		left: 0;
		height: 0;
		transition-duration: .6s;
		z-index: 10;
		top: 5.5rem;
		width: 150%;
		margin-left: -25%;
		overflow: hidden; 
		line-height: 5rem;
		box-shadow: #fff 0 0 .3rem;
		background-color: #fff;
		border-bottom-left-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem; 
	}  

 

	.header .flex-start li:hover ul {
		height: auto;  
		/* transform: ; */
	}

	.header .flex-start li ul a {
		display: block;
	}

	.header .flex-start li ul li {
		height: 3rem;
		line-height: 3rem;
		text-align: center;
		padding: 0;
		margin: 0;
	}

	.pageindex {
		position: absolute;
		width: 100%;
		left: 0;
		padding-top: 3rem;
		z-index: 100000;
	}

	.pageindex .flex-start li span,
	.pageindex .flex-start li a {
		color: #fff;
		transition-duration: .2s;
	}



	header .flex-start li {
		padding: 0 1.07rem;
		height: 5.75rem;
		line-height: 5.375rem;
		border-bottom: transparent solid 0.25rem;
		font-size: 1.1rem;
	}

	header .flex-start li.current {
		color: #986F5A;
		border-bottom: #986F5A solid 0.25rem;
	}

	header .flex-start li.current a {
		color: #986F5A;
	}
	header .flex-start li.current li a {
		color: #333;
	}


	.flex-start li a {
		color: #333;
	}

	.header.pageindex .office {
		right: 6rem;
	}

	header .office {
		position: absolute;
		bottom: 1.6rem;
		right: 0rem;
		width: 8.57rem;
		height: 2.84rem;
		line-height: 2.14rem;
		border-radius: 1.41rem;
		background: linear-gradient(to right, #986F5A, #DBBAA8);
	}


	.pageindex .menu .bgw {
		display: none;
	}

	header.pageindex .menu .office {
		background: #fff;
	}

	header .office img {
		position: absolute;
		width: 8.57rem;
		height: 2.84rem;
	}

	.bounds .logog,
	.bounds .logo {
		width: 26rem;
	}

	.mobelHeader {
		height: 3rem;
		display: none;
	}

	.pageindex:hover .el-icon-search{
		color: #000;
	}
	.pageindex:hover {
		background-color: rgba(255, 255, 255, 1);
		border-color: #000;
	}
	.pageindex:hover .flex-start li span,
	.pageindex:hover .flex-start li a{
		color: #333;
	}
	.pageindex:hover .logog {
		opacity: 1;
	}
	.pageindex:hover .menu .office {
		background: linear-gradient(to right, #986F5A, #DBBAA8);
	}

	.pageindex:hover .menu .bgw {
		display: block;
	}

	.pageindex:hover .menu .bgg {
		display: none;
	}
	
	@media screen and (max-width: 750px) {
		.mobelHeader {
			display: block;
			height: 44px;
		}

		.header,
		.pageindex {
			padding-top: 0;
			height: 44px;
		}

		.mobelHeader .title {
			line-height: 44px;
			text-align: center;
			font-size: 18px;
		}

		header .rightBtn {
			font-size: 16px;
			position: absolute;
			width: 30px;
			height: 30px;
			top: 8px;
			cursor: pointer;
			right: 20px;
		}

		header .rightBtn span {
			display: block;
			background-color: #333;
			position: absolute;
			left: 5px;
			right: 5px;
			height: 2px;
			border-radius: 2px;
			top: 14px;
			transition: .4s;
		}

		header .rightBtn::before {
			background-color: #333;
			position: absolute;
			content: "";
			display: block;
			left: 5px;
			right: 5px;
			height: 2px;
			border-radius: 2px;
			top: 5px;
			transition: .4s;
		}

		header .rightBtn::after {
			position: absolute;
			background-color: #333;
			content: "";
			display: block;
			left: 5px;
			right: 5px;
			height: 2px;
			border-radius: 2px;
			bottom: 5px;
			transition: .4s;
		}

		header .rightBtn.active::before {
			top: 14px;
			transform: rotate(135deg);
		}

		header .rightBtn.active span {
			left: 14px;
			right: 14px;
		}

		header .rightBtn.active::after {
			bottom: 14px;
			transform: rotate(45deg);
		}

		.pcheader {
			display: none;
		}

		.shadow {
			z-index: 10000;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.menus {
			z-index: 10001;
			position: absolute;
			top: 44px;
			right: 0;
			padding: 15px 30px;
			background-color: rgba(0, 0, 0, 0.7);
			color: #fff;
			height: auto;
			line-height: 30px;
		}

		.menus li {
			line-height: 40px;
			padding-right: 30px;
			font-size: 16px;
			position: relative;
		}

		.menus a::before {
			position: absolute;
			right: 0;
			top: 20px;
			content: '';
			display: block;
			width: 8px;
			height: 2px;
			background-color: #fff;
			transform: rotate(135deg);

		}

		.menus a::after {
			position: absolute;
			right: 0;
			top: 15px;
			content: '';
			display: block;
			width: 8px;
			height: 2px;
			background-color: #fff;
			transform: rotate(45deg);
		}

		.menus a {
			color: #fff;
		}

		.pageindex {
			position: relative;
			top: 0;
		}
	}
</style>