import axios, {
	get
} from '@/http'

const permission = {
	state: {
		menus: [],
		config: []
	},
	mutations: {
		SET_CONFIG: (state, config) => {
			state.config = config
		},
		SET_MENUS: (state, menu) => {
			state.menus = menu
		}
	},
	actions: {
		GenerateMenus({
			commit
		}) {
			return new Promise(resolve => {
				get('siteMenu/list', {}, res => {
					if (res.state) {
						commit('SET_MENUS', res.data)
					}
				})
			})
		},
		GenerateConfig({
			commit
		}) {
			return new Promise(resolve => {
				get('siteHomeProfile/info', {}, res => {
					if (res.state) {
						commit('SET_CONFIG', res.data)
					}
				})
			})
		},
	}
}

export default permission