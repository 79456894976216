import store from '@/store' 
const getters = {  
  menus(state,menu){ 
	  let menus = state.permission.menus 
	  if(!menus.length){
		  store.dispatch('GenerateMenus') 
	  }
	  return menus
  },
  config(state,config){
	  let conf = state.permission.config 
	  if(!conf.id){ 
		  store.dispatch('GenerateConfig') 
	  }
	  return conf
  },
}
export default getters
 